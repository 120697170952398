import React from 'react'
import Offerings from '../Offerings'

const styles = {
    section: {},
    text: { color: 'white' }
};
const { section, text } = styles;
const CoursesSection = ({
    heading,
    description,
    gridItems
  }) => (
    <section className='section section--gradient'>
        <div className='container'>
            <div className='section'>
            <div className='columns'>
                <div className='column is-10 is-offset-1'>
                <div className='content'>
                    <div>
                    <h3 className='has-text-weight-semibold is-size-2' style={text}>
                        {heading}
                    </h3>
                    <p>{description}</p>
                    </div>
                    <Offerings gridItems={gridItems.blurbs} />
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )

export default CoursesSection;