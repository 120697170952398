import React from 'react'

const styles = {
    tile: {
        color: 'white'
    }
};
const { tile } = styles;

const DescriptionTile = ({
    heading,
    text
  }) => (
    <div className='column is-4 description-content' style={tile}>
        <h3 className='has-text-weight-semibold is-size-2 heading'>
        {heading}
        </h3>
        <p className='description'>{text}</p>
    </div>
  )

  export default DescriptionTile