import React from 'react'
import DescriptionTile from './DescriptionTile'

const styles = {
    section: { 
        textAlign: 'center',
        backgroundColor: '#1E2345',
        minHeight: '100vh',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    content: {
    },
    headerText: {
        margin: '60px'
    },
    text: {
        color: 'white'
    }
};
const { headerText, content, section, text } = styles;

const DescriptionSection = ({
    heading,
    description
  }) => (
    <section className='section section--gradient' style={section}>
          <div className='columns' style={headerText}>
            <div className='column is-10 is-offset-1'>
              <div className='content'>
                <div>
                  <h3 className='has-text-weight-semibold is-size-2 heading' style={text}>
                    {heading}
                  </h3>
                  <p className='description' style={text}>{description}</p>
                </div>              
              </div>
            </div>
          </div>
          <div className="columns">
            <DescriptionTile heading="Community" text="Build a network of other people in Calgary to learn with" />         
            <DescriptionTile heading="Only takes one weekend" text="Won't take all your time" />         
            <DescriptionTile heading="5:1 Ratio" text="We want you to get all the attention you need" />         
          </div>     
    </section>
  )
  export default DescriptionSection;