import React from 'react'

const styles = {
    section: {
        minHeight: '100vh'
    }
};
const { section } = styles;

const DescriptionSection = () => (
    <section className='section section--gradient' style={section}>
        <div className='container'>
        <div className='section'>
            <div className='columns'>
            <div className='column is-10 is-offset-1'>
                <div className='content'>
                    <div>
                        <h3 className='has-text-weight-semibold is-size-2 heading'>
                        Our focus
                        </h3>
                        <p className='description'>
                            We focus on students and professionals who want to learn on their own with a 
                            low risk transition into software development.  We get you past the hurdles 
                            that make others stop.
                        </p>
                    </div>              
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>
  )

export default DescriptionSection;