import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import DescriptionSection from './DescriptionSection'
import FocusSection from './FocusSection'
import CoursesSection from './CoursesSection'

const styles = {
  text: {
    color: 'white'
  }
};
const { section, text } = styles;

const HomePageTemplate = ({
  heading,
  description,
  offerings,
  meta_title,
  meta_description
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='section section--gradient home-page-intro-section'>
      <div className='container'>
        <div className='section'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='content'>
                <div>
                  <h3 className='has-text-weight-semibold is-size-2'style={text}>
                    {heading}
                  </h3>
                  <p className='description' style={text}>{description}</p>
                </div>         
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FocusSection />
    <DescriptionSection heading={heading} description={description} />
    <CoursesSection heading={heading} description={description} gridItems={offerings}/>
  </div>
)

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,

}

export default HomePageTemplate
